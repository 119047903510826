var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_vm.client === 1 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-apple"
  })]) : _vm._e(), _vm.client === 2 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-android"
  })]) : _vm._e(), _vm.client === 3 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-windows"
  })]) : _vm._e(), _vm.client === 7 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  })]) : _vm._e(), _vm.client === 67 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" E-perpus ")]) : _vm._e(), _vm.client === 82 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v("Gramedia.com ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }