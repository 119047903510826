var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_vm.gateway == 1 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-apple-pay"
  }), _vm._v(" Itunes ")]) : _vm.gateway == 15 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-google-wallet"
  }), _vm._v(" Google Wallet ")]) : _vm.gateway == 12 ? _c('span', [_c('i', {
    staticClass: "fa-solid fa-credit-card"
  }), _vm._v(" CC ")]) : _vm.gateway == 23 ? _c('span', [_c('i', {
    staticClass: "fa-regular fa-building"
  }), _vm._v(" Gramedia ")]) : _vm.gateway == 24 ? _c('span', [_c('i', {
    staticClass: "fa-solid fa-suitcase"
  }), _vm._v(" scoopwallet ")]) : _vm.gateway == 28 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" VA Permata ")]) : _vm.gateway == 29 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" VA Bca ")]) : _vm.gateway == 30 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" VA Mandiri ")]) : _vm.gateway == 31 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" VA BNI ")]) : _vm.gateway == 38 ? _c('span', [_c('i', {
    staticClass: "fa-solid fa-suitcase"
  }), _vm._v("ShoppePay ")]) : _vm.gateway == 33 ? _c('span', [_c('i', {
    staticClass: "fa-solid fa-suitcase"
  }), _vm._v(" Linkaja ")]) : _vm.gateway == 32 ? _c('span', [_c('i', {
    staticClass: "fa-solid fa-suitcase"
  }), _vm._v(" Gopay ")]) : _vm.gateway == 35 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" CIMB Clicks ")]) : _vm.gateway == 36 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" CIMB go Mobile ")]) : _vm.gateway == 37 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v("CIMB RekPon ")]) : _vm.gateway == 3 ? _c('span', [_c('i', {
    staticClass: "fa-brands fa-firefox-browser"
  }), _vm._v(" FREE Purchase ")]) : _vm.gateway == 50 ? _c('span', [_c('i', {
    staticClass: "fa-solid fa-ticket"
  }), _vm._v(" Qris ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.gateway) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }