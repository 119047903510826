<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something went wrong
            </b-alert>
          </b-col>
        </b-row>
        <h2 class="title h2 text-center mt-3 text-primary">Log Subledger</h2>
        <div class="card-body">
          <div class="col-md-12 pt-2">
            <div class="form-group row">
              <span class="col-sm-auto">Select Range</span>
              <div class="col-md-auto">
                <v-date-picker
                  v-model="range"
                  mode="date"
                  :masks="masks"
                  :max-date="new Date()"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div class="rows">
                      <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                        :max-date="new Date()"
                      />

                      <span class="p-2">to</span>

                      <input
                        class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        :max-date="new Date()"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="col-md-auto">
                <button class="btn btn-block btn-primary" @click="fetchData">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <span class="col-sm-1">Filter</span>
              <div class="col-md-auto">
                <multiselect
                  v-model="payment"
                  label="name"
                  track-by="name"
                  placeholder="Payment Gateway"
                  open-direction="bottom"
                  :options="paymentOption"
                  :searchable="true"
                  :loading="isLoadingPayment"
                  :close-on-select="true"
                  :multiple="false"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="col-md-auto">
                <multiselect
                  v-model="client"
                  label="text"
                  track-by="text"
                  placeholder="Client ID"
                  open-direction="bottom"
                  :options="clientOption"
                  :searchable="true"
                  :loading="isLoadingClient"
                  :close-on-select="true"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="col-md-auto">
                <multiselect
                  v-model="platform"
                  label="text"
                  track-by="text"
                  placeholder="Platform ID"
                  open-direction="bottom"
                  :options="platformOption"
                  :searchable="true"
                  :loading="isLoadingPlatform"
                  :close-on-select="true"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="col-md-auto">
                <multiselect
                  v-model="Filter.offer_type"
                  label="name"
                  track-by="name"
                  placeholder="Offer type"
                  open-direction="bottom"
                  :options="Offers"
                  :searchable="true"
                  :loading="isLoadingOfferType"
                  :close-on-select="true"
                  :multiple="false"
                >
                  <span slot="noResult">
                    Oops! No elements found. Consider changing the search query.
                  </span>
                </multiselect>
              </div>
              <div class="col-md-auto">
                <multiselect
                  v-model="Filter.vendor_id"
                  :options="vendors"
                  placeholder="Publisher"
                  label="Vendor Name"
                  :close-on-select="true"
                  :custom-label="customLabel"
                  @search-change="asyncFind"
                  :searchable="true"
                  :loading="isLoadingSearch"
                  track-by="name"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <span class="col-sm-1">Search</span>
              <div class="col-sm-4">
                <input
                  v-model="search"
                  type="text"
                  class="form-control"
                  placeholder="Berani Tidak Disukai..."
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(no)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(ordernumber)="row">
              {{ row.item?.order_number }}
            </template>

            <template #cell(offername)="row">
              {{ row.item?.item_details?.offer_name }}
            </template>

            <template #cell(accSubType)="row">
              {{ row.item?.offer_type }}
            </template>

            <template #cell(vendor)="row">
              {{ row.item?.vendor_name }}
            </template>

            <template #cell(paymentgateway)="row">
              <Payment :gateway="parseInt(row.item.paymentgateway_id)" />
            </template>

            <template #cell(client)="row">
              <div style="width: 150px">
                <Client :client="parseInt(row.item.client_id)" />
              </div>
            </template>

            <template #cell(amount)="row">
              {{ formatPrice(row.item?.disbursement, row.item?.currency_code) }}
            </template>

            <template #cell(period)="row">
              <div style="width: 100px">
                {{ formatDate(row.item?.disbursement_period) }}
              </div>
            </template>

            <template #cell(detail)="row">
              <b-button
                size="sm"
                @click="row.toggleDetails"
                class="mr-2 btn-success"
              >
                {{ row.detailsShowing ? 'Hide' : 'Show' }}
              </b-button>
            </template>

            <template #row-details="row">
              <div class="row">
                <div class="col-sm-6">
                  <span class="font-weight-bold">Item Details</span>
                  <pre>{{
                    JSON.stringify(row.item.item_details, null, 2)
                  }}</pre>
                </div>
                <div class="col-sm-6">
                  <span class="font-weight-bold">Payment Details</span>
                  <pre>{{
                    JSON.stringify(row.item.payment_detail, null, 2)
                  }}</pre>
                </div>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import constant from '../../store/constant';
import Payment from '../../components/payments/gateway.vue';
import Client from '../../components/client/client.vue';

const clientID = Object.entries(constant.CLIENT_ID).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));
const platformID = Object.entries(constant.PLATFORM).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));

export default {
  name: 'LogSubledger',
  components: {
    Payment,
    Client,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      range: {
        start: moment()
          .startOf('month')
          .subtract(30, 'D')
          .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      Filter: {
        vendor_id: '',
        offer_type: '',
      },
      isLoadingOfferType: false,
      fields: [
        {
          key: 'no',
          label: 'No.',
        },
        {
          key: 'ordernumber',
          label: 'Order Number',
        },
        {
          key: 'offername',
          label: 'Offer Name',
        },
        {
          key: 'accSubType',
          label: 'Account Subtype',
        },
        {
          key: 'vendor',
          label: 'Vendor',
        },
        {
          key: 'paymentgateway',
          label: 'Payment Gateway',
        },
        {
          key: 'client',
          label: 'Client',
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'period',
          label: 'Period',
        },
        {
          key: 'detail',
          label: 'Detail',
        },
      ],
      Offers: [
        {
          name: 'Buffet',
          value: 'buffet',
        },
        {
          name: 'Single',
          value: 'single',
        },
        {
          name: 'Subscription',
          value: 'subscription',
        },
        {
          name: 'Bundle',
          value: 'bundle',
        },
      ],
      payment: null,
      isLoadingPayment: false,
      paymentOption: [],

      client: null,
      isLoadingClient: false,
      clientOption: [...clientID],

      platform: null,
      isLoadingPlatform: false,
      platformOption: [...platformID],

      search: null,
    };
  },
  watch: {
    currentPage: function() {
      this.fetchData();
    },
    payments: function() {
      if (!Object.keys(this.payments).length) return;
      this.paymentOption = this.payments;
    },

    errorMessage: function() {
      if (!Object.keys(this.errorMessage).length) return;
      this.messageAlert('error', this.errorMessage);
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Log Subledger | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      totalRows: (state) => state.logSubledger.totalRows,
      items: (state) => state.logSubledger.items,
      payments: (state) => state.payments.items,
      isLoading: (state) => state.logSubledger.isLoading,
      isError: (state) => state.logSubledger.isError,
      successMessage: (state) => state.logSubledger.successMessage,
      errorMessage: (state) => state.logSubledger.errorMessage,
      vendors: (state) => state.vendors.items,
      isLoadingSearch: (state) => state.vendors.isLoading,
    }),
  },
  mounted() {
    this.actionGetPayment();
  },
  methods: {
    ...mapActions('logSubledger', ['fetchLogLedger']),
    ...mapActions('payments', ['fetchAllPayment']),
    ...mapActions('vendors', ['searchVendors']),

    fetchData() {
      const payload = {
        from: moment(this.range.start).format('YYYY-MM-DD'),
        to: moment(this.range.end).format('YYYY-MM-DD'),
        vendor_id: this.Filter.vendor_id ? this.Filter.vendor_id.id : false,
        offer_type: this.Filter.offer_type
          ? this.Filter.offer_type.value
          : false,
        paymentgateway_id: this.payment ? this.payment.id : false,
        platform_id: this.platform ? this.platform.value : false,
        client_id: this.client ? this.client.value : false,
        searchText: this.search,
        page: this.currentPage,
        limit: this.perPage,
      };
      console.log(this.currentPage);
      this.fetchLogLedger(payload);
      this.setQuery(payload);
    },

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    actionGetPayment() {
      this.isLoadingPayment = true;
      if (!this.paymentOption.length) {
        const payload = {
          status: true,
        };
        this.fetchAllPayment(payload);
      }

      this.isLoadingPayment = false;
    },

    asyncFind(query) {
      if (query.length > 3) {
        this.isLoadingSearch = true;
        setTimeout(() => {
          let payload = {
            q: query,
            limit: 10,
          };
          this.searchVendors(payload);
        }, 800);
      }
    },

    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'Type Here';
      }
    },

    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },

    formatDate(tgl) {
      return moment(tgl).format('YYYY-MM-DD');
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
};
</script>
<style scoped>
input::placeholder {
  font-style: italic;
}
</style>
