<template>
  <div class="text-center">
    <span v-if="gateway == 1">
      <i class="fa-brands fa-apple-pay"></i> Itunes
    </span>
    <span v-else-if="gateway == 15">
      <i class="fa-brands fa-google-wallet"></i> Google Wallet
    </span>
    <span v-else-if="gateway == 12">
      <i class="fa-solid fa-credit-card"></i> CC
    </span>
    <span v-else-if="gateway ==23">
      <i class="fa-regular fa-building"></i> Gramedia
    </span>
    <span v-else-if="gateway ==24">
      <i class="fa-solid fa-suitcase"></i> scoopwallet
    </span>
    <span v-else-if="gateway == 28">
      <i class="fa-brands fa-firefox-browser"></i> VA Permata
    </span>
    <span v-else-if="gateway == 29">
      <i class="fa-brands fa-firefox-browser"></i> VA Bca
    </span>
    <span v-else-if="gateway == 30">
      <i class="fa-brands fa-firefox-browser"></i> VA Mandiri
    </span>
    <span v-else-if="gateway == 31">
      <i class="fa-brands fa-firefox-browser"></i> VA BNI
    </span>
     <span v-else-if="gateway == 38">
      <i class="fa-solid fa-suitcase"></i>ShoppePay
    </span>
     <span v-else-if="gateway == 33">
      <i class="fa-solid fa-suitcase"></i> Linkaja
    </span>
    <span v-else-if="gateway == 32">
      <i class="fa-solid fa-suitcase"></i> Gopay
    </span>
     <span v-else-if="gateway == 35">
      <i class="fa-brands fa-firefox-browser"></i> CIMB Clicks
    </span>
    <span v-else-if="gateway == 36">
      <i class="fa-brands fa-firefox-browser"></i> CIMB go Mobile
    </span>
    <span v-else-if="gateway == 37">
      <i class="fa-brands fa-firefox-browser"></i>CIMB RekPon
    </span>
    <span v-else-if="gateway == 3">
      <i class="fa-brands fa-firefox-browser"></i> FREE Purchase
    </span>
    <span v-else-if="gateway == 50">
      <i class="fa-solid fa-ticket"></i> Qris
    </span>
    <span v-else>
      {{ gateway }}
    </span>
  </div>
</template>

<script>
export default {
  name: "gateway",
  props: {
    gateway: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false,
    }
  }
};
</script>