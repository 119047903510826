<template>
  <div class="text-center">
    <span v-if="client === 1">
      <i class="fa-brands fa-apple"></i>
    </span>
    <span v-if="client === 2">
      <i class="fa-brands fa-android"></i>
    </span>
    <span v-if="client === 3">
      <i class="fa-brands fa-windows"></i>
    </span>
    <span v-if="client === 7">
      <i class="fa-brands fa-firefox-browser"></i>
    </span>
    <span v-if="client === 67">
      <i class="fa-brands fa-firefox-browser"></i> E-perpus
    </span>
    <span v-if="client === 82">
      <i class="fa-brands fa-firefox-browser"></i>Gramedia.com
    </span>
  </div>
</template>

<script>
export default {
  name: "client",
  props: {
    client: {
      type: Number,
      required: true
    }
  }
};

</script>
